import request from '@/utils/request'
export default {
  //首页
  getTemByHtml(data) {
    let mcode = data.mcode || ''
    let url = mcode === "" ? '/api/v1/templateFieldHtml/findTemplateByHtml' : '/api/v1/templateFieldHtml/findTemplateByHtmlDB'
    return request({
      url: url,
      method: 'get',
      params: data
    })
  },
  //自主入职查询
  getEntryTemByHtml(data) {
    let mcode = data.mcode || ''
    let url = mcode === "" ? '/api/v1/templateFieldHtml/findEntryTemplateByHtml' : '/api/v1/templateFieldHtml/findMogoFieldList'
    return request({
      url: url,
      method: 'get',
      params: data
    })
  },
  //根据分类查字段详情
  getFieldByMenu(data) {
    let mcode = data.mcode || ''
    let url = mcode === "" ? '/api/v1/templateFieldHtml/findFieldByMenuId' : '/api/v1/templateFieldHtml/findFieldByMenuIdDB'
    return request({
      url: url,
      method: 'get',
      params: data
    })
  },
  //保存基本信息
  addBasicFieldVale(data) {
    let mcode = data.mcode || ''
    let url = mcode === "" ? '/api/v1/templateFieldHtml/addFieldVale' : '/api/v1/templateFieldHtml/updateFieldValeMdb'
    return request({
      url: url,
      method: 'post',
      data
    })
  },
  //上传图片
  ssWorkingServicePolicyUploadMultipartFile(data) {
    return request({
      url: '/api/v1/uploadFile/uploadFile',
      method: 'post',
      data
    })
  },
  //大保存
  saveFieldVale(data) {
    let mcode = data.mcode || ''
    let url = mcode === "" ? '/api/v1/templateFieldHtml/saveFieldVale' : '/api/v1/templateFieldHtml/saveAgainFieldVale'

    return request({
      url: url,
      method: 'post',
      data
    })
  },
  //   ORC身份信息  
  findOcrByIdCard(data) {
    return request({
      url: '/api/v1/templateFieldHtml/findOcrByIdCard',
      method: 'post',
      data
    })
  },
  // ORC银行卡信息 
  findOcrByBankCard(data) {
    return request({
      url: '/api/v1/templateFieldHtml/findOcrByBankCard',
      method: 'post',
      data
    })
  },
  //获取网址 shortUrl/UnYNFb
  getShortUrl(data) {
    return request({
      url: '/api/v1/shortUrl/getLongUrl',
      method: 'get',
      params: data
    })
  }
}
