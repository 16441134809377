<template>
  <div id="app">
    <router-view />

    <BottomView></BottomView>
  </div>
</template>
<script>
import BottomView from "@/components/bottom-view/index";
import API from "./api/http";
import untis from "./utils";

export default {
  components: { BottomView },
  created() {
    //短链接是的跳转
    let search = untis.getSessionStorage("href");
    console.log("s,earch", search);

    if (search) {
      API.getShortUrl({ key: search }).then((res) => {
        untis.removeSessionStorage("href");
        window.location.replace(res.data);
      });
    }
  },
};
</script>
<style>
#app {
  min-height: 100%;
  width: 100%;
  background-color: #f5f5f5;
  position: relative;
}
@media screen and (min-width: 1200px) {
  html,
  body {
    width: 720px !important;
    font-size: 60px !important;
    margin: 0 auto;
  }
  .info-cen {
    min-height: 96% !important;
  }
  .footer {
    margin-top: 30px;
  }
  .wrapper {
    padding-bottom: 30px;
  }
}
</style>
