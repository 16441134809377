

import untis from '../../utils'

const state = {
  clientCode: untis.getSessionStorage('clientCode') || '', //租户code
  ctFieldStatisticalId: untis.getSessionStorage('ctFieldStatisticalId') || '',//模板id
  temporaryCode: untis.getSessionStorage('temporaryCode') || '', //临时code
  errList:[],//错误页面数据
  mcode:untis.getSessionStorage('mcode') || ''
  
}
const getters = {
  clientCode: state => state.clientCode,
  ctFieldStatisticalId: state => state.ctFieldStatisticalId,
  temporaryCode: state => state.temporaryCode,
  errList: state => state.errList,
  mcode: state => state.mcode,
}
const mutations = {
  SET_CLIENCODE(state, clientCode) {
    untis.setSessionStorage('clientCode', clientCode)
    state.clientCode = clientCode
  },
  SET_CTFIELDID(state, ctFieldStatisticalId) {
    untis.setSessionStorage('ctFieldStatisticalId', ctFieldStatisticalId)
    state.ctFieldStatisticalId = ctFieldStatisticalId
  },
  SET_TMPORARYCODE(state, temporaryCode) {
    untis.setSessionStorage('temporaryCode', temporaryCode)
    state.temporaryCode = temporaryCode
  },
  SET_ERRLIST(state, arr) {
    state.errList = arr
  },
  SET_MCODE(state, mcode){
    untis.setSessionStorage('mcode', mcode)
    state.mcode = mcode
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations
}