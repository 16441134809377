
<template>
  <div class="app-container" :class="{ 'app-transition': headerHiden }">
    <div class="main" v-if="showH">
      <HeaderMenu :bottomItems="bottomItems"></HeaderMenu>
      <router-view></router-view>
    </div>
    <!-- <div class="error-container" v-else>出错了</div> -->
  </div>
</template>

<script>
import HeaderMenu from "../../components/header-menu/index";
import BottomView from "../../components/bottom-view/index";
import API from "../../api/http";
import { mapGetters } from "vuex";
import untis from "../../utils";
export default {
  name: "Layout",
  data() {
    return {
      headerHiden: true,
      bottomItems: [],
      showH: false,
    };
  },
  components: { HeaderMenu, BottomView },
  computed: {
    ...mapGetters("user", {
      clientCode: "clientCode",
      ctFieldStatisticalId: "ctFieldStatisticalId",
      temporaryCode: "temporaryCode",
      mcode: "mcode",
    }),
  },
  created() {
    //获取网址参数
    if (untis.getUrlValue("mcode")) {
      //提交后  根据hr发送的链接进入页面
      if (!this.mcode || this.mcode === "null") {
        let code = untis.getUrlValue("mcode");
        this.$store.commit("user/SET_MCODE", code);
      }
    }
    //初次进入页面
    if (!this.clientCode || this.clientCode === "null") {
      let clientcode = untis.getUrlValue("clientCode");
      let calid = untis.getUrlValue("cid");
      this.$store.commit("user/SET_CLIENCODE", clientcode);
      this.$store.commit("user/SET_CTFIELDID", calid);
    }
    if (this.clientCode) {
      this.getInit();
    }
  },
  mounted() {
    window.addEventListener("scroll", this.scrollEvent, false);
  },
  methods: {
    getInit() {
      API.getTemByHtml({
        clientCode: this.clientCode,
        ctFieldStatisticalId: this.ctFieldStatisticalId,
        mcode: this.mcode,
      })
        .then((res) => {
          this.showH = true;
          let result = res.data;
          untis.removeSessionStorage("href");
          if (!this.temporaryCode) {
            this.$store.commit("user/SET_TMPORARYCODE", result.temporaryCode);
          }
          this.setTem(result);
        })
        .catch(() => {
          this.showH = false;
        });
    },
    setTem(result) {
      untis.setSessionStorage("hello", {
        welcomeContent: result.welcomeContent,
        welcomeAvatarUrl: result.welcomeAvatarUrl,
      });
      untis.setSessionStorage("company", {
        aboutName: result.aboutName,
        aboutIntroduce: result.aboutIntroduce,
        aboutPropagandaUrl: result.aboutPropagandaUrl,
      });

      this.bottomItems = [
        {
          text: "自主入职",
          path: "/user",
          name: "user",
        },
      ];
      if (result.isDisplayWelcome === 1) {
        this.bottomItems.unshift({
          text: "欢迎页",

          path: "/hello",
          name: "hello",
        });
      }
      if (result.isDisplayAboutUs === 1) {
        this.bottomItems.push({
          text: "企业介绍",
          path: "/company",
          name: "company",
        });
      }
      this.bottomItems = this.bottomItems.map((item, index) => {
        return {
          ...item,
          index: index,
        };
      });
    },
    scrollEvent() {
      // scrollHeight（文档内容实际高度，包括超出视窗的溢出部分）、
      // scrollTop（滚动条滚动距离）、
      // clientHeight（窗口可视范围高度）。
      const clientHeight =
        document.documentElement.clientHeight || document.body.clientHeight;
      // 设备屏幕高度
      const scrollTop = window.pageYOffset; // div 到头部的距离
      const scrollHeight = document.body.scrollHeight; // 滚动条的总高度
      //滚动条到底部的条件
      if (scrollTop > 100) {
        this.headerHiden = false;
      } else {
        this.headerHiden = true;
      }
    },
  },
};
</script>
<style>
.app-container {
  width: 100%;
  height: 100%;
  color: #fff;
  text-align: center;
  position: relative;
}
.error-container {
  color: #141614;
}
/* .app-transition .header-menu {
  transition: transform 0.2s ease-in-out;
  transform: translate(0, -100px);
}
.app-container .header-menu { 
  transition: transform 0.2s ease-in-out;
  transform: translate(0, 0);
} */
</style>
