import {baseDomain} from '@/config'
export default {
  //获取地址栏参数
  getUrlParam(name) {
    //unescape数可对通过 escape() 编码的字符串进行解码。
    var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)");
    let url = window.location.href.split("#")[0];
    let search = url.split("?")[1];
    if (search) {
      var r = search.substr(0).match(reg);
      if (r !== null) return unescape(r[2]);
      return null;
    } else return null;
  },

  /**
 * 存储sessionStorage
 */
  setSessionStorage(name, content) {
    if (!name) {
      return
    }
    if (typeof content !== 'string') {
      content = JSON.stringify(content)
    }
    window.sessionStorage.setItem(name, content)
  },

  /**
   * 获取sessionStorage
   */
  getSessionStorage(name) {
    if (!name) {
      return true
    } else {
      return window.sessionStorage.getItem(name)
    }
  },
  /**
   * 删除sessionStorage
   */
  removeSessionStorage(name) {
    if (!name) {
      return
    }
    window.sessionStorage.removeItem(name)
  },
  /**
 * 存储localStorage
 */
  setStore(name, content) {
    if (!name) {
      return
    }
    if (typeof content !== 'string') {
      content = JSON.stringify(content)
    }
    window.localStorage.setItem(name, content)
  },

  /**
   * 获取localStorage
   */
  getStore(name) {
    if (!name) {
      return true
    } else {
      return window.localStorage.getItem(name)
    }
  },

  /**
   * 删除localStorage
   */
  removeStore(name) {
    if (!name) {
      return
    }
    window.localStorage.removeItem(name)
  },
  /**
 * 省份证/银行卡 密文显示
 */
  setIdCard(idNumber) {
    var getCard = String(idNumber)
      .replace(/\s/g, '')
      .replace(/^(\w{2})\d+(\w{2})$/, '$1 ****** $2')
    return getCard
  },
  /**
   * 获取网址参数
   */
  getUrlValue(name) {
    var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)"); //构造一个含有目标参数的正则表达式对象
    var r = window.location.search.substr(1).match(reg); //匹配目标参数
    if (r != null) {
      return decodeURIComponent(r[2]);
    }
    return null; //返回参数值
  },
  /**
   * 拼接图片地址
   */
  getImgUrl () {
  /*  // 去除域名的m否则找不到图片
    const href = window.location.host
    let lastSrc
    if (href.indexOf('localhost') !== -1) {
    //   lastSrc = 'dev.tranderpay.com/'
    // } else if (href === 'm.tranderpay.com') {
    //   lastSrc = 'www.tranderpay.com/'
    } else {
      lastSrc = href.startsWith('m') ? href.substr(1) : href
    }
    if (href.indexOf('h5') !== -1) {
      lastSrc = lastSrc.replace('h5', '')
    } */
    return 'https://' + baseDomain + '/tranderpayfiles'
  },
  /**
   * 证件号码
   */
  setIdCardText(value) {
    /* 身份证 */
    const idCard = /^[1-9]\d{7}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{3}$|^[1-9]\d{5}[1-9]\d{3}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{3}([0-9]|X)$/
    console.log('idCard', idCard.test(value))
    if (idCard.test(value)) {
      return true
    }
    return false
  }
}


