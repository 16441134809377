<template>
  <div class="header-menu">
    <div
      class="menu-tab"
      :class="{ 'menu-tab-active': headerIndex == index }"
      v-for="(item, index) in bottomItems"
      :key="item.text"
      @click="handleChange(item)"
    >
      <span
        :style="{
          'border-color':
            headerIndex == index ? backgroundColor : 'transparent',
        }"
        >{{ item.text }}</span
      >
    </div>
  </div>
</template>
<script>
import untis from "../../utils";
export default {
  props: {
    bottomItems: {
      type: Array,
    },
  },
  data() {
    return {
      backgroundColor: "#2E98FF",
      // bottomItems: [],
      // headerIndex: 0,
    };
  },

  computed: {
    headerIndex() {
      return this.$store.state.menu.headerId;
    },
  },
  // watch: {
  //   $route() {
  //     this.getBreadcrumb();
  //   },
  // },
  created() {
    if (this.headerIndex >= this.bottomItems.length) {
      untis.removeSessionStorage("headerId");
    } else {
      let item = this.bottomItems[this.headerIndex];
      //catch:Vue报错避免冗余导航解决方法
      // this.$router.replace(path:item.path).catch((err) => err);
      this.$router.replace({path:item.path,query:this.$route.query || {}}).catch((err) => err);
    }
  },
  mounted() {
    // this.getBreadcrumb();
  },
  methods: {
    // getBreadcrumb() {
    //   this.bottomItems.forEach((item) => {
    //     if (item.name === this.$route.name) {
    //       this.headerIndex = item.index;
    //     }
    //   });
    // },
    handleChange(item) {
      // this.$router.replace(item.path);
      this.$router.replace({path:item.path,query:this.$route.query || {}})
      this.$store.commit("menu/headerId", item.index);
    },
  },
};
</script>
<style lang="scss" scoped>
.header-menu {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 50px;
  background-color: #fff;
  color: #141614;
  display: flex;
  border-bottom: 1px solid #e8e8e8;
}
.menu-tab {
  flex: 1;
  text-align: center;
  span {
    display: inline-table;
    line-height: 47px;
    border-bottom: 3px solid;
  }
}
</style>