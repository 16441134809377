import Vue from 'vue'
import VueRouter from 'vue-router'
import Layout from '../views/Layout/index.vue'
import untis from "../utils";


Vue.use(VueRouter)
const routes = [
  {
    path: '/index.html',
    redirect: '/'
  },
  {
    path: '/',
    component: Layout,
    children: [
      {
        path: '/hello',
        component: () => import(/* webpackChunkName: "hello" */ '../views/hello/index.vue'),
        name: 'hello',
        meta: {
          title: '欢迎页',
        },
      },
      {
        path: '/user',
        component: () => import(/* webpackChunkName: "user" */ '../views/user/index.vue'),
        name: 'user',
        meta: {
          title: '自主入职',
        },
      },
      {
        path: '/company',
        component: () => import(/* webpackChunkName: "company" */ '../views/company/index.vue'),
        name: 'company',
        meta: {
          title: '企业介绍',
        },
      }

    ]
  },
  {
    path: '/basic',
    component: () => import(/* webpackChunkName: "basic" */ '../views/basic/index.vue'),
    name: 'basic',
    meta: {
      title: '基本信息',
    },
  },
  {
    path: '/language',
    component: () => import(/* webpackChunkName: "language" */ '../views/language/index.vue'),
    name: 'language',
    meta: {
      title: '语言',
    },
  },
  {
    path: '/positionInformation',
    component: () => import(/* webpackChunkName: "positionInformation" */ '../views/positionInformation/index.vue'),
    name: 'positionInformation',
    meta: {
      title: '职称信息',
    },
  },
  {
    path: '/bank',
    component: () => import(/* webpackChunkName: "bank" */ '../views/bank/index.vue'),
    name: 'bank',
    meta: {
      title: '工资卡信息',
    },
  },
  {
    path: '/sociology',
    component: () => import(/* webpackChunkName: "sociology" */ '../views/sociology/index.vue'),
    name: 'sociology',
    meta: {
      title: '社会关系',
    },
  },
  {
    path: '/teach',
    component: () => import(/* webpackChunkName: "teach" */ '../views/teach/index.vue'),
    name: 'teach',
    meta: {
      title: '社会关系',
    },
  },
  {
    path: '/custom',
    component: () => import(/* webpackChunkName: "custom" */ '../views/custom/index.vue'),
    name: 'custom',
    meta: {
      title: '自定义模版',
    }
  },
  {
    path: '/work',
    component: () => import(/* webpackChunkName: "work" */ '../views/work/index.vue'),
    name: 'work',
    meta: {
      title: '工作经历',
    },
  },
  {
    path: '/skill',
    component: () => import(/* webpackChunkName: "skill" */ '../views/skill/index.vue'),
    name: 'skill',
    meta: {
      title: '技能专长',
    },
  },
  {
    path: '/train',
    component: () => import(/* webpackChunkName: "train" */ '../views/train/index.vue'),
    name: 'train',
    meta: {
      title: '培训经历',
    },
  },
  {
    path: '/enclosure',
    component: () => import(/* webpackChunkName: "enclosure" */ '../views/enclosure/index.vue'),
    name: 'enclosure',
    meta: {
      title: '附件资料',
    },
  },
  {
    path: '/submitErr',
    component: () => import(/* webpackChunkName: "submitErr" */ '../views/submitErr/index.vue'),
    name: 'submitErr',
    meta: {
      title: '错误提示',
    },
  },
  {
    path: '/404',
    component: () => import(/* webpackChunkName: "error" */ '../views/error/index.vue'),
    hidden: true
  },

]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {

  let clientcode2 = untis.getUrlValue("clientCode");
  let calid = untis.getUrlValue("cid");
  let mcode = untis.getUrlValue("mcode");
  let clientCode = untis.getSessionStorage("clientCode") || ''
  //获取网址短链接参数
  var protocol = window.location.protocol;
  let host = window.location.host;
  let herfName = protocol + '//' + host
  let urlKey = window.location.href.substr(herfName.length + 1)

  if (clientCode === '' || !clientCode) {
    if (to.path !== '/' && clientcode2) {

      console.log('1', clientcode2)
      // if (clientcode2) {

      next({
        path: '/',
        query: {
          clientCode: clientcode2,
          cid: calid,
          mcode: mcode
        }
      })
      // } else {
      // console.log('2')
      // alert(2)
      // alert(urlKey);
      // //短链接时的跳转
      // untis.setSessionStorage("href", urlKey)
      // next({
      //   path: '/'
      // })
      // }

    } else {

      console.log('2', clientCode)

      if (!clientcode2 || clientcode2 === '') {
        untis.setSessionStorage("href", urlKey)
      }
      next()
    }

  } else {
    console.log('3', clientCode)

    if (!clientcode2 || clientcode2 === '') {
      untis.setSessionStorage("href", urlKey)
    }
    next()
  }

})

export default router
